import React, {useContext, useEffect, useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import {API_URL, PHONE_NUMBER} from "../../utils/constants";
import fr from "../../utils/FR.json";
import {GlobalContext} from "./GlobalProvider";
import {message} from "antd";

const Navbar = ({sticky}) => {
    const [changer, setChanger] = useState(true);
    const {token, removeToken} = useContext(GlobalContext)

    setTimeout(() => {
        setChanger(!changer);
    }, [5000]);

    const [horaire, setHoraire] = useState([]);

    async function getHoraires() {
        try {
            const response = await fetch(API_URL + "afficherHoraire", {
                method: "GET",
            })
            const res = await response.json()
            if (res.status === 200) {
                setHoraire(res.data)
            } else {
                message.error(res.message)
            }
        } catch (e) {
            message.error("Une erreur s'est produite lors de la récupération des horaires")
        }
    }

    useEffect(() => {
        getHoraires()
    }, []);

    const getJour = (text, horaireJour) => {
        const date = new Date();

        const [heure, minute] = horaireJour[text].split(":");
        return new Date(
            +date.getFullYear(),
            +date.getMonth(),
            +date.getDate(),
            +heure,
            +minute,
            +"00"
        );
    };

    const ouvertFerme = () => {
        try {
            const date = new Date();
            const jourJ = {
                1: "Lundi",
                2: "Mardi",
                3: "Mercredi",
                4: "Jeudi",
                5: "Vendredi",
                6: "Samedi",
                0: "Dimanche",
            };
            let horaireJour = null;
            for (let jour of Object.values(horaire)) {
                if (jour["jourSemaine"] === jourJ[date.getDay()]) {
                    horaireJour = jour;
                }
            }
            const time = date.getTime();
            const ouvertMatin = getJour("ouvertAM", horaireJour);
            const fermerMatin = getJour("fermerAM", horaireJour);
            const ouvertAprem = getJour("ouvertPM", horaireJour);
            const fermerAprem = getJour("fermerPM", horaireJour);

            if (horaireJour["general"]) {
                if (
                    (time > ouvertMatin.getTime() &&
                        time < fermerMatin.getTime()) ||
                    (time > ouvertAprem.getTime() &&
                        time < fermerAprem.getTime())
                ) {
                    if (
                        time > ouvertMatin.getTime() &&
                        time < fermerMatin.getTime()
                    ) {
                        if (horaireJour["matin"]) {
                            return fr.navbarStateOpen;
                        }
                        return fr.navbarStateClose;
                    }
                    if (
                        time > ouvertAprem.getTime() &&
                        time < fermerAprem.getTime()
                    ) {
                        if (horaireJour["aprem"]) {
                            return fr.navbarStateOpen;
                        }
                        return fr.navbarStateClose;
                    }
                    return fr.navbarStateClose;
                }
                return fr.navbarStateClose;
            } else {
                return fr.navbarStateClose;
            }
        } catch (err) {
            return fr.navbarErrorServer;
        }
    };

    const navigate = useNavigate();

    return (
        <nav
            className={
                sticky
                    ? "navbar navbar-expand-lg navbar-dark fixed-top py-lg-0 px-lg-5 wow fadeIn bg-dark"
                    : "navbar navbar-expand-lg navbar-dark fixed-top py-lg-0 px-lg-5 wow fadeIn"
            }
            data-wow-delay="0.1s"
        >
            <img
                className="mt-2"
                src={require("../../img/logo.png")}
                style={{width: "200px"}}
                onClick={() => {
                    navigate(fr.routeHome);
                }}
                alt="Logo de la boulangerie l'Épi d'Or d'Orléans"
            />
            <button
                type="button"
                className="navbar-toggler me-4"
                data-bs-toggle="collapse"
                data-bs-target="#navbarCollapse"
            >
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav mx-auto p-4 p-lg-0 rounded">
                    <NavLink to={fr.routeHome} className="nav-item nav-link">
                        <span
                            data-bs-target="#navbarCollapse"
                            data-bs-toggle="collapse"
                        >
                            <i className="bi bi-house"></i> {fr.navbarHome}
                        </span>
                    </NavLink>
                    <div className="nav-item dropdown">
                        <NavLink
                            className="nav-link dropdown-toggle"
                            data-bs-toggle="dropdown"
                            to="/Shop"
                        >
                            <i className="bi bi-shop-window"></i>{" "}
                            {fr.navbarShop}
                        </NavLink>
                        <div className="dropdown-menu m-0">
                            <NavLink
                                to={fr.routeShopProduct}
                                className="dropdown-item"
                            >
                                <span
                                    data-bs-target="#navbarCollapse"
                                    data-bs-toggle="collapse"
                                >
                                    <i className="bi bi-basket-fill"/>{" "}
                                    {fr.navbarShopProduct}
                                </span>
                            </NavLink>
                            <li>
                                <hr className="dropdown-divider"/>
                            </li>
                            <NavLink
                                to={fr.routeShopCake}
                                className="dropdown-item"
                            >
                                <span
                                    data-bs-target="#navbarCollapse"
                                    data-bs-toggle="collapse"
                                >
                                    <i className="bi bi-award-fill"/>{" "}
                                    {fr.navbarShopCake}{" "}
                                </span>
                            </NavLink>

                        </div>
                    </div>
                    <NavLink to={fr.routeAbout} className="nav-item nav-link">
                        <span
                            data-bs-target="#navbarCollapse"
                            data-bs-toggle="collapse"
                        >
                            <i className="bi bi-geo-alt"></i> {fr.navbarAbout}{" "}
                        </span>
                    </NavLink>
                    <NavLink to={fr.routeContact} className="nav-item nav-link">
                        <span
                            data-bs-target="#navbarCollapse"
                            data-bs-toggle="collapse"
                        >
                            <i className="bi bi-envelope"></i>{" "}
                            {fr.navbarContact}
                        </span>
                    </NavLink>
                    <div className="nav-item dropdown">
                        <NavLink
                            className="nav-link dropdown-toggle"
                            data-bs-toggle="dropdown"
                            to="/Other"
                        >
                            <i className="bi bi-plus-circle"></i>{" "}
                            {fr.navbarOther}
                        </NavLink>
                        <div className="dropdown-menu m-0">
                            <NavLink
                                to={fr.routeOtherNotice}
                                className="dropdown-item"
                            >
                                <span
                                    data-bs-target="#navbarCollapse"
                                    data-bs-toggle="collapse"
                                >
                                    <i className="bi-star-fill me-1"></i>{" "}
                                    {fr.navbarOtherAvis}
                                </span>
                            </NavLink>
                            <li>
                                <hr className="dropdown-divider"/>
                            </li>
                            <NavLink
                                to={fr.routeOtherPhoto}
                                className="dropdown-item"
                            >
                                <span
                                    data-bs-target="#navbarCollapse"
                                    data-bs-toggle="collapse"
                                >
                                    <i className="bi bi-camera-fill"></i>{" "}
                                    {fr.navbarOtherPhoto}
                                </span>
                            </NavLink>
                        </div>
                    </div>
                    {token &&
                        <>
                            <NavLink to={(fr.routeManagement).replace(":onglet", "0")} className="nav-item nav-link">
                        <span
                            data-bs-target="#navbarCollapse"
                            data-bs-toggle="collapse"
                        >
                            <i className="bi bi-gear "></i>{" "}
                            Administration
                        </span>
                            </NavLink>
                            <NavLink to="/" className="nav-item nav-link" onClick={() => removeToken()}>
                        <span
                            data-bs-target="#navbarCollapse"
                            data-bs-toggle="collapse"
                        >
                            <i className="bi bi-box-arrow-in-left"></i>{" "}
                            Déconnexion
                        </span>
                            </NavLink></>
                    }
                </div>

                <div
                    className=" d-none d-lg-flex"
                    style={{
                        minWidth: "195px",
                        maxWidth: "195px",
                        maxHeight: "95px",
                    }}
                >
                    <div className="flex-shrink-0 btn-lg-square border border-light rounded-circle">
                        <a
                            href={`tel:${PHONE_NUMBER}`}
                            style={{
                                textDecoration: "none",
                                color: "inherit",
                            }}
                        >
                            <i className="fa fa-phone text-primary"></i>
                        </a>
                    </div>
                    {ouvertFerme() === fr.navbarErrorServer ? (
                        <div className="ps-3">
                            <small className="text-primary mb-0">
                                {fr.navbarState}
                            </small>

                            <p className={"text-danger fs-5 mb-0"}>
                                {fr.navbarErrorServer}
                            </p>
                        </div>
                    ) : (
                        <div className="ps-3">
                            <small className="text-primary mb-0">
                                {changer ? fr.navbarCall : fr.navbarState}
                            </small>
                            {changer ? (
                                <p className="text-light fs-5 mb-0 pl-3">
                                    <a
                                        href={`tel:${PHONE_NUMBER}`}
                                        style={{
                                            textDecoration: "none",
                                            color: "inherit",
                                        }}
                                    >
                                        {PHONE_NUMBER}
                                    </a>
                                </p>
                            ) : (
                                <p
                                    className={
                                        ouvertFerme() === "Ouvert"
                                            ? "text-success fs-5 mb-0"
                                            : "text-danger fs-5 mb-0"
                                    }
                                >
                                    {ouvertFerme()}
                                </p>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
